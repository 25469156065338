import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --layout-background: linear-gradient(135deg, #005077 1%, #000814 10%);

    --table-border-bottom: #0B2447;
    --table-header-background: #0B2447;
    --highlight-to: #0B2447;
    --wrapper-background: #0B2447;

    --table-background: #19376D;
    --modal-background: #19376D;

    --text-primary: #93dbca;
    --text-secondary: #93dbca;

    --background-primary: #032b4a;
    --scrollbar-track: #2d313c;
    --scrollbar-thumb: #5b5f67;
    --slider-track: #2ad2c1;
    --slider-handle-border: #2ad2c1;
    --table-row-hover: #324b52;
    --select-placeholder: #AC3B61;
    --divider-border: #434a59;
    --highlight-from: #2abdd2;
  }

  html, body {
    background: var(--background-primary);
  }
  input[type=number]::-webkit-inner-spin-button {
    opacity: .25;
  }
  input[type=number]:hover::-webkit-inner-spin-button,
  input[type=number]:focus::-webkit-inner-spin-button {
    opacity: 1;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb);
  }
  .ant-slider-track, .ant-slider:hover .ant-slider-track {
    background-color: var(--slider-track);
    opacity: 0.75;
  }
  .ant-slider-track,
  .ant-slider ant-slider-track:hover {
    background-color: var(--slider-track);
    opacity: 0.75;
  }
  .ant-slider-dot-active,
  .ant-slider-handle,
  .ant-slider-handle-click-focused,
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)  {
    border: 2px solid var(--slider-handle-border); 
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--table-row-hover);
  }
  .custom-select-placeholder .ant-select-selection-placeholder {
    color: var(--select-placeholder); 
  }
  .ant-table-tbody > tr > td {
    border-bottom: 8px solid var(--table-border-bottom);
  }
  .ant-table-container table > thead > tr:first-child th {
    border-bottom: none;
  }
  .ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid var(--divider-border) !important;
  }
  .ant-layout {
    background: var(--layout-background);
  }
  .ant-table {
    background: var(--table-background);
  }
  .ant-table-thead > tr > th {
    background: var(--table-header-background);
  }
  .ant-select-item-option-content {
    img {
      margin-right: 4px;
    }
  }
  .ant-modal-content {
    background-color: var(--modal-background);
  }
  @-webkit-keyframes highlight {
    from { background-color: var(--highlight-from);}
    to {background-color: var(--highlight-to);}
  }
  @-moz-keyframes highlight {
    from { background-color: var(--highlight-from);}
    to {background-color: var(--highlight-to);}
  }
  @keyframes highlight {
    from { background-color: var(--highlight-from);}
    to {background-color: var(--highlight-to);}
  }
  .flash {
    -moz-animation: highlight 0.5s ease 0s 1 alternate ;
    -webkit-animation: highlight 0.5s ease 0s 1 alternate;
    animation: highlight 0.5s ease 0s 1 alternate;
  }
`;
