import React from 'react';
import { Tabs } from 'antd';
import {
  useOpenOrdersAccounts,
  useWalletBalancesForAllMarkets,
  useCustomMarkets,
  useTokenAccounts
} from '../utils/markets';
import FloatingElement from '../components/layout/FloatingElement';
import WalletBalancesTable from '../components/UserInfoTable/WalletBalancesTable';
import { useMintToTickers, parseTokenAccountData } from '../utils/tokens';

const { TabPane } = Tabs;

export default function BalancesPage() {
  const { customMarkets } = useCustomMarkets(); // Use the custom markets
  const mintToTickers = useMintToTickers(); // Make sure this includes your custom tokens
  const [tokenAccounts] = useTokenAccounts(); // Use the existing token accounts
  
  // Get a set of all mints involved in custom markets
  const customMarketMints = new Set(customMarkets.flatMap(market => [market.baseLabel, market.quoteLabel]));

  // Filter token accounts to include only those that correspond to the mints from custom markets
  const customMarketBalances = tokenAccounts?.filter(account => 
    customMarketMints.has(account.effectiveMint.toBase58())
  ).map(account => {
    const mint = account.effectiveMint.toBase58();
    let parsedAccount
    if (account.account?.data) {
      parsedAccount = parseTokenAccountData(account.account.data);
    } else {
      parsedAccount = 0
    }
    const balance = parsedAccount.amount; 
    const coin = mintToTickers[mint]; // Assume you have ticker info for all custom market mints

    return {
      coin,
      mint,
      walletBalance: balance,
      openOrdersFree: 0, // This will be computed if you have open orders data
      openOrdersTotal: 0, // This will be computed if you have open orders data
    };
  });

  // openOrdersBalances logic would follow, you'd need to make sure it's also filtered by custom markets

  return (
    <FloatingElement style={{ flex: 1, paddingTop: 10 }}>
      <Tabs defaultActiveKey="walletBalances">
        <TabPane tab="Wallet Balances" key="walletBalances">
          <WalletBalancesTable walletBalances={customMarketBalances || []} />
        </TabPane>
      </Tabs>
    </FloatingElement>
  );
}

