import { Connection, PublicKey } from '@solana/web3.js';
import { Market } from '@project-serum/serum';
import {
    useMarket,
  } from '../../utils/markets';
import { Orderbook } from '@project-serum/serum';


const TOKEN_PROGRAM_ID = new PublicKey("TokenAAGbeQq5tGW2r5RoR3oauzN2EkNFiHNPw9q34s")

const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey("Dt8fRCpjeV6JDemhPmtcTKijgKdPxXHn9Wo9cXY5agtG")

const dexProgramId = new PublicKey('DexobvLtDf7UbtNJQgf5SsuExkS1JaftvAMNsnEiAvxL');

export async function fetchOrderBook(connection: Connection, market: Market) {
    if (market) {
        const bids = await market.loadBids(connection);
        const asks = await market.loadAsks(connection);
        return { bids, asks };
    }
  }

async function getBlockTimeFromSlot(connection: Connection, slot: number): Promise<number | null> {
    try {
        const blockTime = await connection.getBlockTime(slot);
        //console.log("Blocktime:", blockTime);
        return blockTime;
    } catch (error) {
        console.error("Error fetching block time from slot:", error);
    }
    return null;
}

async function fetchEventQueue(connection: Connection, market: Market) {
    try {
        const eventQueue = await market.loadEventQueue(connection);
        //console.log("Loaded event queue:", eventQueue);
        return eventQueue.map(event => {
            try {
                return market.parseFillEvent(event);
            } catch (parseError) {
                console.error("Error parsing fill event:", parseError);
                return null;
            }
        }).filter(event => event !== null);
    } catch (error) {
        console.error("Error loading event queue:", error);
        return [];
    }
}

export async function fetchTrades(connection: Connection, market: Market): Promise<Record<string, any>[]> {
    if (market) {
        const fills: Record<string, any>[] = await market.loadFills(connection);
        const tradesWithTimestamps: Record<string, any>[] = [];

        const eventQueue = await fetchEventQueue(connection, market);

        for (const fill of fills) {
            try {
                const event = eventQueue.find(event => event.orderId.eq(fill.orderId)); // Match event with fill
                if (event && event.openOrdersSlot !== undefined && event.openOrdersSlot !== null) {
                    const blockTime = await getBlockTimeFromSlot(connection, event.openOrdersSlot);
                    tradesWithTimestamps.push({ ...fill, timestamp: blockTime });
                } else {
                    //console.log("Fill without slot or event not found:", fill);
                    tradesWithTimestamps.push({ ...fill, timestamp: null });
                }
            } catch (error) {
                console.error("Error processing fill:", fill, error);
                tradesWithTimestamps.push({ ...fill, timestamp: null });
            }
        }

        return tradesWithTimestamps;
    }
    return [];
}

export function processOrderBook(orderbook: Orderbook) {
    const orders: { price: number, size: number }[] = [];
    const levels = orderbook.getL2(20); // Can adjust as we need
    levels.forEach(([price, size]) => {
      orders.push({
        price,
        size,
      });
    });
    return orders;
  }

export function processTrades(trades: any[]): { time: number, value: number }[] {
    return trades.map(trade => ({
      time: Math.floor(new Date(trade.timestamp * 1000).getTime() / 1000), 
      value: trade.price,
    }));
}