import BalancesTable from './BalancesTable';
import OpenOrderTable from './OpenOrderTable';
import React from 'react';
import { Tabs, Typography } from 'antd';
import FillsTable from './FillsTable';
import FloatingElement from '../layout/FloatingElement';
import FeesTable from './FeesTable';
import { useOpenOrders, useBalances, useMarket } from '../../utils/markets';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

export default function Index() {
  const { market } = useMarket();
  return (
    <FloatingElement style={{ flex: 1, paddingTop: 20, background: 'var(--wrapper-background)' }}>
      <Typography>
        <Paragraph style={{ color: 'var(--text-primary)' }}>
          Make sure to go to Balances and click Settle to send out your funds.
        </Paragraph>
        <Paragraph style={{ color: 'var(--text-primary)' }}></Paragraph>
      </Typography>
      <Tabs defaultActiveKey="orders" style={{ color: 'var(--text-primary)' }}>
        <TabPane
          tab={<span style={{ color: 'var(--text-primary)' }}>Open Orders</span>}
          key="orders"
        >
          <OpenOrdersTab />
        </TabPane>
        <TabPane
          tab={<span style={{ color: 'var(--text-primary)' }}>Recent Trade History</span>}
          key="fills"
        >
          <FillsTable />
        </TabPane>
        <TabPane
          tab={<span style={{ color: 'var(--text-primary)' }}>Balances</span>}
          key="balances"
        >
          <BalancesTab />
        </TabPane>

      </Tabs>
    </FloatingElement>
  );
}

/*
        {market && market.supportsSrmFeeDiscounts ? (
          <TabPane
            tab={<span style={{ color: '#99e8d5' }}>Fee discounts</span>}
            key="fees"
          >
            <FeesTable />
          </TabPane>
        ) : null}

*/
const OpenOrdersTab = () => {
  const openOrders = useOpenOrders();

  return <OpenOrderTable openOrders={openOrders} />;
};

const BalancesTab = () => {
  const balances = useBalances();

  return <BalancesTable balances={balances} />;
};
