import React, { useEffect, useRef } from 'react';
import './index.css';
import { useMarket, USE_MARKETS } from '../../utils/markets';
import { createChart, CrosshairLineOptions, CrosshairMode, LineStyle } from 'lightweight-charts';
import { fetchOrderBook, fetchTrades, 
  processOrderBook, processTrades } from './data';
import { useConnection } from '../../utils/connection';


export const TVChartContainer = () => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const connection = useConnection();
  const { market } = useMarket();

  useEffect(() => {
    if (!chartContainerRef.current || !connection || !market) {
      return;
    }

    const chart = createChart(chartContainerRef.current,
      {
        layout: {
            background: { color: '#000814' },
            textColor: '#93dbca',
        },
        grid: {
            vertLines: { color: '#444' },
            horzLines: { color: '#444' },
        },
        rightPriceScale: {
          borderColor: '#71649C',
        },
        timeScale: {
          borderColor: '#71649C',
        },
        crosshair: {
          // Change mode from default 'magnet' to 'normal'.
          // Allows the crosshair to move freely without snapping to datapoints
          mode: CrosshairMode.Magnet,
  
          // Vertical crosshair line (showing Date in Label)
          vertLine: {
              color: '#C3BCDB44',
              style: LineStyle.Solid,
              labelBackgroundColor: '#9B7DFF',
          },
  
          // Horizontal crosshair line (showing Price in Label)
          horzLine: {
              color: '#9B7DFF',
              labelBackgroundColor: '#9B7DFF',
          },
        }
      }
    );
    const lineSeries = chart.addLineSeries({
      color: '#00b7ff',
  });

    async function loadData() {
      if (!market) {
        console.error('Market is not defined');
        return;
      }

      //console.log('Fetching order book...');
      const orderBook = await fetchOrderBook(connection, market);
      if (!orderBook) {
        console.error('Failed to fetch order book');
        return;
      }
      //console.log('Order book fetched:', orderBook);

      const { bids, asks } = orderBook;

      //console.log('Fetching trades...');
      const trades = await fetchTrades(connection, market);
      //console.log('Trades fetched:', trades);

      //console.log('Processing order book...');
      const processedBids = processOrderBook(bids);
      const processedAsks = processOrderBook(asks);
      //console.log('Processed bids:', processedBids);
      //console.log('Processed asks:', processedAsks);

      //console.log('Processing trades...');
      let processedTrades
      if (trades) {
        processedTrades = processTrades(trades);
      }
      
      //console.log('Processed trades:', processedTrades);

      // Combine processed data for charting
      lineSeries.setData(processedTrades);
    }

    loadData();

    return () => chart.remove();
  }, [connection, market]);

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};